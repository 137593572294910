<template>
    <div :class="`m-3 p-2 wrapper  wrapper--default`">
        <div class="text-center p-2">
            <h3 class="text-center">Halen/Wordt gebracht per dag - {{ today }} </h3>
            <span @click="previousDay"><i class="fas fa-arrow-left"></i></span>
            <span class="text-center m-2">{{ day }}-{{ month }}-{{ year }}</span>
            <span @click="nextDay"><i class="fas fa-arrow-right"></i></span>
        </div>
        <Loading v-if="loading" />
        <div v-else>
            <Tabs>
                <Tab class="p-1" title="Wordt gebracht">
                    <table :class="`w-100 table--default table__border--default`">
                        <thead :class="`table__head--default`">
                            <tr>
                                <th class="table__head--text">Item</th>
                                <th class="table__head--text">Omschrijving</th>
                                <th class="table__head--text" style="min-width: 100px">
                                    Verwacht
                                </th>
                                <th class="table__head--text">Betaald</th>
                                <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                                    class="table__head--text">
                                    Leverancier
                                </th>
                                <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                                    class="table__head--text">
                                    Locatie
                                </th>
                                <th class="table__head--text">
                                    Opmerking
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, key) in brought_orders_daily" :key="key" class="table__row--color">
                                <td class="table__cell--default">
                                    <ImageHover :itemnummer="item.itemnummer" :bu="bu" />
                                </td>
                                <td class="table__cell--default">{{ item.voertuig }}</td>
                                <td class="table__cell--default">{{ item.aanleverdatum }}</td>
                                <td class="table__cell--default">
                                    <div v-if="item.betaald == 1">
                                        <span v-b-tooltip.hover :title="item.betaaldatum"><i
                                                class="fas fa-euro-sign"></i></span>
                                    </div>
                                    <div v-else>
                                        <span v-b-tooltip.hover title="Nog niet betaald"><i
                                                class="fab fa-creative-commons-nc-eu"></i></span>
                                    </div>
                                </td>
                                <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                                    class="table__cell--default">
                                    {{ item.bedrijf }}
                                </td>
                                <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                                    class="table__cell--default">
                                    {{ item.locatie }}
                                </td>
                                <td class="table__data--right table__cell--default">
                                    {{ item.opmerkingen }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Tab>
                <Tab title="Halen" class="p-1">
                    <table :class="`w-100 table--default table__border--default`">
                        <thead :class="`table__head--default`">
                            <tr>
                                <th class="table__head--text">Status</th>
                                <th class="table__head--text">Item</th>
                                <th class="table__head--text">Omschrijving</th>
                                <th class="table__head--text" style="min-width: 100px">Datum</th>
                                <th class="table__head--text">Betaald</th>
                                <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                                    class="table__head--text">
                                    Leverancier
                                </th>
                                <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                                    class="table__head--text">
                                    Locatie
                                </th>
                                <th class="table__head--text">Transporteur</th>
                                <th class="table__data--right table__head--text">Kosten</th>
                                <th class="table__head--text">
                                    Opmerking
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, key) in pickup_orders_daily" :key="key" class="table__row--color">
                                <td class="table__cell--default">
                                    <div v-if="item.statusnaam == 'nieuw'">
                                        <span class="dot dot--red" v-b-tooltip.hover title="Nieuw"></span>
                                    </div>
                                    <div v-else>
                                        <span class="dot dot--blue" v-b-tooltip.hover title="Ingepland"></span>
                                    </div>
                                </td>
                                <td class="table__cell--default">
                                    <ImageHover :itemnummer="item.itemnummer" :bu="item.bu" />
                                </td>
                                <td class="table__cell--default">{{ item.voertuig }}</td>
                                <td class="table__cell--default">{{ item.aanleverdatum }}</td>
                                <td class="table__cell--default">
                                    <div v-if="item.betaald == 1">
                                        <span v-b-tooltip.hover :title="item.betaaldatum"><i
                                                class="fas fa-euro-sign"></i></span>
                                    </div>
                                    <div v-else>
                                        <span v-b-tooltip.hover title="Nog niet betaald"><i
                                                class="fab fa-creative-commons-nc-eu"></i></span>
                                    </div>
                                </td>
                                <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                                    class="table__cell--default">
                                    {{ item.bedrijf }}
                                </td>
                                <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                                    class="table__cell--default">
                                    {{ item.locatie }}
                                </td>
                                <td class="table__cell--default" v-if="item.transporteur">
                                    {{ item.transporteur }}
                                </td>
                                <td class="table__cell--default" v-else></td>
                                <td class="table__data--right table__cell--default">
                                    € {{ item.kosten }}
                                </td>
                                <td class="table__data--right table__cell--default">
                                    {{ item.opmerkingen }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Tab>
            </Tabs>
        </div>
    </div>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";

import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import { subtractTime, addTime } from "@/functions/date.js";

export default {
    components: { Loading, ImageHover, Tab, Tabs },
    data: () => ({
        loading: null,
        brought_orders_daily: null,
        pickup_orders_daily: null,
        today: null,
    }),
    created() {
        this.full_date = this.moment();
        this.getData();
    },
    methods: {
        getData() {
            this.day = this.full_date.date();
            this.month = this.full_date.month() + 1;
            this.year = this.full_date.year();
            this.today = this.full_date.format('DD-MM-YYYY')
            this.loading = true;
            request(`brought-orders-daily/${this.full_date.format('YYYY-MM-DD')}`, "GET").then(({ brought_orders_daily }) => {
                this.brought_orders_daily = brought_orders_daily;

            });
            request(`pickup-orders-daily/${this.full_date.format('YYYY-MM-DD')}`, "GET").then(({ pickup_orders_daily }) => {
                this.pickup_orders_daily = pickup_orders_daily;
                this.loading = false;
            })
        },

        previousDay() {
            this.full_date = subtractTime(1, this.full_date, "days");
            this.getData(this.bu);
        },

        nextDay() {
            this.full_date = addTime(1, this.full_date, "days");
            this.getData(this.bu);
        },
    },
};
</script>